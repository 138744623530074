'use client';

import { authenticate } from '@/lib/actions';
import { Button, Input } from '@hx/ui/inputs';
import { RiLoader4Line } from '@remixicon/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { SubmitHandler, useForm } from '@hx/ui/inputs';
import { error } from '@/ui/toasts';
type Inputs = {
  username: string;
  password: string;
};
export default function LoginForm() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<Inputs>();
  const [loading, setLoading] = useState(false);
  const nextURL = searchParams.get('nextURL') || '/';
  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    const res = await authenticate(data);
    if ('error' in res) {
      setLoading(false);
      error('Alog salió mal.', 'Ocurrió un problema con tu acceso. Comprueba tu email y contraseña e intenta de nuevo.');
      return;
    }
    router.push(nextURL);
  };
  return <form onSubmit={handleSubmit(onSubmit)} data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <div className="grid w-full items-center gap-4">
        <div className="grid w-full items-center">
          <Input data-test-id="username" id="username" placeholder="Usuario" {...register('username')} data-sentry-element="Input" data-sentry-source-file="LoginForm.tsx" />
        </div>
        <div className="grid w-full items-center">
          <Input data-test-id="password" type="password" id="password" placeholder="Contraseña" {...register('password')} data-sentry-element="Input" data-sentry-source-file="LoginForm.tsx" />
        </div>
        <Button data-test-id="login-button" type="submit" disabled={loading} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
          {loading ? <RiLoader4Line className="mr-2 h-4 w-4 animate-spin" /> : 'Ingresar'}
        </Button>
      </div>
    </form>;
}