'use client';

import { HandThumbUpIcon } from '@heroicons/react/24/outline';
import { Toaster, toast } from '@hx/ui/feedback';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { RiErrorWarningLine } from '@remixicon/react';
import { useTheme } from 'next-themes';
function info(title: string, description?: string) {
  toast.info(title, {
    description,
    icon: <InfoCircledIcon className="h-5 w-5" />,
    classNames: {
      title: 'text-primary'
    }
  });
}
function success(title: string, description?: string) {
  toast.success(title, {
    description,
    icon: <HandThumbUpIcon className="h-5 w-5 text-green-400" />,
    classNames: {
      title: 'text-green-600'
    }
  });
}
function warning(title: string, description?: string) {
  toast.warning(title, {
    description,
    icon: <RiErrorWarningLine className="h-5 w-5 text-orange-400" />,
    classNames: {
      title: 'text-orange-400'
    }
  });
}
function error(title: string, description?: string) {
  toast.error(title, {
    description,
    icon: <RiErrorWarningLine className="h-5 w-5 text-red-400" />,
    classNames: {
      title: 'text-red-400 '
    }
  });
}
const Toasters = () => {
  const {
    theme
  } = useTheme();
  return <Toaster theme={theme as any} position="top-right" richColors closeButton data-sentry-element="Toaster" data-sentry-component="Toasters" data-sentry-source-file="toasts.tsx" />;
};
export { Toasters, info, success, error, warning };